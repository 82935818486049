import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRouteLandingPageGuard } from '@shared/auth/auth-route-landingpage-guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('landing_page/landingpage.module').then(m => m.LandingPageModule), // Lazy load account module
        data: { preload: true },
        canActivate : [AppRouteLandingPageGuard]
    },
    {
        path: 'account',
        loadChildren: () => import('account/account.module').then(m => m.AccountModule), // Lazy load account module
        data: { preload: true }
    },
    {
        path: 'app',
        loadChildren: () => import('app/app.module').then(m => m.AppModule), // Lazy load account module
        data: { preload: true }
    },
    {
        path: 'update',
        loadChildren: () => import('update/update.module').then(m => m.UpdateModule), // Lazy load account module
        data: { preload: true }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []
})
export class RootRoutingModule { }
