import { TenantAvailabilityState } from '@shared/service-proxies/service-proxies';


export class AppTenantAvailabilityState {
    static Available: number = TenantAvailabilityState.Available;
    static InActive: number = TenantAvailabilityState.InActive;
    static NotFound: number = TenantAvailabilityState.NotFound;
}

export enum TYPE_VALIDATE {
    Number = 1,
    Email = 2,
    Moment = 3,
    MinValue = 4,
    MaxValue = 5,
    PassWord = 6,
    Phone = 7,
    CheckDuplicates = 8,
    Dynamic = 9,
    Datetime = 10,
    CCCD = 11,
    MaBHYT = 12,
    ValidRef = 13,
    Array = 14,
    Equal = 15,
    Ipv4 = 16,
    Ipv6 = 17,
    OnlyNumberAndChar= 18
}
