import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IndexedDbService {
  private dbName = 'myDatabase';
  private dbVersion = 1;
  private db: IDBDatabase;

  constructor() {
    this.openDatabase();
  }

  private openDatabase(): void {
    const request = indexedDB.open(this.dbName, this.dbVersion);

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBRequest).result;
      if (!db.objectStoreNames.contains('myStore')) {
        db.createObjectStore('myStore', { keyPath: 'id' });
      }
    };

    request.onsuccess = (event) => {
      this.db = (event.target as IDBRequest).result;
      console.log('Database opened successfully');
    };

    request.onerror = (event) => {
      console.error('Error opening database', event);
    };
  }

 // indexeddb.service.ts
async addData(key,data: any): Promise<void> {
    await this.waitForDatabase();  // Đảm bảo cơ sở dữ liệu đã mở
    const transaction = this.db!.transaction(['myStore'], 'readwrite');
    const store = transaction.objectStore('myStore');
    const request = store.put({id: key, data: data});
    request.onsuccess = () => console.log('Data added successfully');
    request.onerror = (event) => console.error('Error adding data', event);
  }

  async getData(id: string): Promise<any> {
    await this.waitForDatabase();  // Đảm bảo cơ sở dữ liệu đã mở
    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction(['myStore'], 'readonly');
      const store = transaction.objectStore('myStore');
      const request = store.get(id);

      request.onsuccess = (event) => resolve(request.result);
      request.onerror = (event) => reject('Error retrieving data');
    });
  }

  async deleteData(id: string): Promise<void> {
    await this.waitForDatabase();  // Đảm bảo cơ sở dữ liệu đã mở
    const transaction = this.db!.transaction(['myStore'], 'readwrite');
    const store = transaction.objectStore('myStore');
    const request = store.delete(id);

    request.onsuccess = () => console.log('Data deleted successfully');
    request.onerror = (event) => console.error('Error deleting data', event);
  }

  private async waitForDatabase() {
    if (!this.db) {
      return new Promise<void>((resolve, reject) => {
        const interval = setInterval(() => {
          if (this.db) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    }
  }

}
