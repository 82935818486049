export class AppConsts {
  static readonly tenancyNamePlaceHolderInUrl = "{TENANCY_NAME}";
  static readonly LockLogin = "SetLockLogin";
  static readonly RefreshBalance = "RefreshBalance";
  static readonly Refcode = "Refcode";

  static remoteServiceBaseUrl: string;
  static appBaseUrl: string;
  static appBaseHref: string; // returns angular's base-href parameter value if used during the publish

  static localeMappings: any = [];

  static readonly userManagement = {
    defaultAdminUserName: "admin",
  };

  static readonly localization = {
    defaultLocalizationSourceName: "newPMS",
  };

  static readonly authorization = {
    encryptedAuthTokenName: "enc_auth_token",
  };

  static readonly grid = {
    defaultPageSize: 10,
    defaultPageSizes: [5, 10, 15, 20, 25, 30, 50, 80, 100],
  };
}

export class TableConst {
  //Table
  static readonly CATEGORIES = "categories";
  static readonly SERVICES = "services";

  //CommonEnum
  static readonly SERVICE_TYPE = "service_type";
  static readonly SERVICE_TELE_TYPE = "service_tele_type";
  static readonly SERVICE_UPDATE_TYPE = "service_update_type";
  static readonly SOURCE_SERVICE = "source_service";
  static readonly ORDER_STATUS = "order_status";
  static readonly ORDER_DETAIL_STATUS = "order_detail_status";
  static readonly COMPLAINT_CATEGORY = "complaint_category";
  static readonly COMPLAINT_ISSUE = "complaint_issue";
  static readonly PAYMENT_METHOD = "payment_method";
  static readonly PAYMENT_TYPE = "payment_type";
  static readonly PAYMENT_TRACKING_SOURCE = "payment_tracking_source";
  static readonly REFUND_METHOD = "refund_method";
  static readonly REFUND_STATUS = "refund_status";
}
