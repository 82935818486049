import { Injector, ElementRef } from '@angular/core';
import { AppConsts, TableConst } from '@shared/AppConsts';
import {
    LocalizationService,
    PermissionCheckerService,
    FeatureCheckerService,
    NotifyService,
    SettingService,
    MessageService,
    AbpMultiTenancyService
} from 'abp-ng2-module';
import { AppSessionService } from './session/app-session.service';
import { ModalHelper } from '@delon/theme/public_api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TYPE_VALIDATE } from './AppEnums';
import { IndexedDbService } from './indexDb/indexDB.service';


export abstract class AppComponentBase {

    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    elementRef: ElementRef;
    modalService: NzModalService;
    indexedDbService : IndexedDbService
    tableConst = TableConst;
    TYPE_VALIDATE = TYPE_VALIDATE;
    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.elementRef = injector.get(ElementRef);
        this.modalService = injector.get(NzModalService);
        this.indexedDbService = injector.get(IndexedDbService);
    }

    l(key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, this.localizationSourceName);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }
    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    FormatString(str: string, ...val: any[]) {
        for (let index = 0; index < val.length; index++) {
            str = str.replace(`{${index}}`, val[index]);
        }
        return str;
    }

    getWidthMobile(widthMobile: number, widthDeskop?: number) {
        if (this.isWitdhMobile()) {
            return widthMobile;
        }
        return widthDeskop;
    }

    // refreshData(arrField, obj) {
    //     arrField.forEach(item => {
    //         obj[item] = null;
    //     });
    // }

    isWitdhMobile()
    {
        let screen = window.innerWidth;
        return screen <= 720;
    }
    makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    getCodeValue(dataItem: any, dataField: string, value: any, isMulti?: boolean) {
        if (isMulti) {
            if (value != null) {
                dataItem[dataField] = value;
            }
            else {
                dataItem[dataField] = null;
            }
        }
        else {
            if (value != null) {
                dataItem[dataField] = value.displayText;
            }
            else {
                dataItem[dataField] = null;
            }
        }
    }

    convertTextAreaToHtml(string) {
        if (string) {
            return abp.utils.replaceAll(string, "\n", "</br>")
        }
    }

    // setTitleTab(title: string) {
    //     this.reuseTabService.title = title;
    //     this.titleSrv.setTitle(title);
    // }

    // closeReutabActive(url: string) {
    //     setTimeout(() => {
    //         this.reuseTabService.close(url);
    //     });
    // }

    static convertBase64ToPdf(base64) {

        // Decode base64 using atob method
        var raw = window.atob(base64);
        // Create an array to store the decoded data
        var uInt8Array = new Uint8Array(raw.length);
        // blob can only receive binary encoding, need to talk about base64 converted to binary and then stuffed
        for (var i = 0; i < raw.length; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        // A return value is given here. In other methods, you can get the converted blob by using the base64 encoding.

        const blob = new Blob([uInt8Array], { type: 'application/pdf' })

        return blob;
    }

}
