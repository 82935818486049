import { Injectable } from "@angular/core";
import { PermissionCheckerService } from "abp-ng2-module";
import { AppSessionService } from "../session/app-session.service";

import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { UrlHelper } from "@shared/helpers/UrlHelper";
import { AppConsts } from "@shared/AppConsts";

@Injectable()
export class AppRouteGuard {
  constructor(
    private _permissionChecker: PermissionCheckerService,
    private _router: Router,
    private _sessionService: AppSessionService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this._sessionService.user && !this.isAccountUrl(state.url)) {
      this._router.navigate(["/account/login"]);
      return false;
    }

    if (this._sessionService.user && (this.isAccountUrl(state.url) || state.url == AppConsts.appBaseUrl)) {
      this._router.navigate([this.selectBestRoute()]);
      return false;
    }

    if (!route.data || !route.data["permission"]) {
      return true;
    }

    if (this._permissionChecker.isGranted(route.data["permission"])) {
      return true;
    }

    this._router.navigate([this.selectBestRoute()]);
    return false;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: any,state : any) {
    return this.canActivate(route.data, null);
}
  selectBestRoute(): string {
    if (!this._sessionService.user) {
      return "/account/login";
    }

    if (this._permissionChecker.isGranted("Pages_Order_NewOrder")) {
      return "/app/order/new-order";
    }

    return "/app/home";
  }

   isAccountUrl(url): boolean {
    return url && url.indexOf('account') >= 0;
}
}
