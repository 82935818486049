import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { LocalizePipe } from '@shared/pipes/localize.pipe';

import { AbpPaginationControlsComponent } from './components/pagination/abp-pagination-controls.component';
import { AbpValidationSummaryComponent } from './components/validation/abp-validation.summary.component';
import { AbpModalHeaderComponent } from './components/modal/abp-modal-header.component';
import { AbpModalFooterComponent } from './components/modal/abp-modal-footer.component';
import { LayoutStoreService } from './layout/layout-store.service';

import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { SHARED_ZORRO_MODULES } from './shared-zorro.module';
// import { NzIconTestModule } from './nz-icon.module';
import { AlainThemeModule } from '@delon/theme';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import { RedoOutline } from '@ant-design/icons-angular/icons';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';

const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
  };
  
  const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => {
    const i = antDesignIcons[key];
    return i;
  });
// #region your componets & directives
const COMPONENTS = [];
const DIRECTIVES = [];
// #endregion

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxPaginationModule,
        ...SHARED_ZORRO_MODULES,
        //  NzIconTestModule ,
        NzIconModule.forChild(icons),
    ],
    declarations: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
         // your components
        ...COMPONENTS,
        ...DIRECTIVES,
       
    ],
    exports: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        AlainThemeModule,
        ...SHARED_ZORRO_MODULES,
         // your components
        ...COMPONENTS,
        ...DIRECTIVES
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard,
                LayoutStoreService,
            ]
        };
    }
}
