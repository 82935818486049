import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        //danh mục
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.DM_ServiceServiceProxy,
        ApiServiceProxies.DM_CategoryServiceProxy,
        //Order
        ApiServiceProxies.OrderServiceProxy,
        ApiServiceProxies.Order_OrderDetailServiceProxy,
        ApiServiceProxies.Order_RefillServiceProxy,
        //Complaint
        ApiServiceProxies.C_ComplaintServiceProxy,
        //payment
        ApiServiceProxies.P_PaymentsServiceProxy,
        ApiServiceProxies.P_PaymentAccountServiceProxy,
        ApiServiceProxies.P_BalanceTrackingServiceProxy,
        //reference
        ApiServiceProxies.ReferenceServiceProxy,
        ApiServiceProxies.RefundServiceProxy,




        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
